@use '../../../../globals';

#navigation-links-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  order: 2;
  height: min-content;

  > a {
    font-size: 14.5pt;
    white-space: nowrap;
    color: white;
    background-color: transparent;
    line-height: normal;
    text-align: center;
    text-decoration: none;
    justify-content: center;

    margin-right: 20px;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column;

    > a {
      display: flex;
      color: black;
      line-height: 3rem;
    }
  }
}
