@use './src/globals';

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: min-content;
  contain: paint; // newer alternative to overflow: hidden.
  background-image: url('assets/background-design.svg');
  background-repeat: no-repeat;
  text-align: center;
  font-size: medium;

  a {
    text-decoration: none;
  }
}

#page-content {
  margin-inline: var(--current-global-margin);
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > * {
    margin-bottom: 10vh;
  }

  > .first-section {
    > .container-HorizontalSection {
      > .right-element {
      }
      > .left-element {
      }
    }
  }
}

.section {
  display: flex;
  flex-direction: row;
  height: fit-content;
  align-items: center;
  margin-bottom: 2%;

  > img {
    width: 50%;
  }

  @include globals.in-mobile-layout {
    flex-direction: column;
    > img {
      margin-top: 5vh;
      width: 100%;
    }
  }
}

.first-section {
  margin-top: 0 !important;
}

.text {
  text-align: left;
  @include globals.in-mobile-layout {
    width: 100%;
    text-align: center;
  }
}

@include globals.in-mobile-layout {
  .image-absolute {
    margin: 0;
    width: 0;
    height: 0;
  }
}

#text-box-container {
  display: flex;
  justify-content: center;

  width: 100%;
  height: min-content;

  > #text-box {
    width: 70%;
    padding-inline: 50px;
    padding-block: 25px;
    border-radius: 25px;

    background-color: white;
    box-shadow: globals.$banner-box-shadow;
    border-radius: globals.$banner-radius-large;
  }
}
