@use './src/globals';

#employees-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#about-section.first-section {
  > .container-HorizontalSection {
    > .right-element {
      > img {
        width: 100%;
      }
    }
  }
}
