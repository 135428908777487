@use '../../../globals';

.container-HorizontalSection {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  @include globals.in-mobile-layout {
    flex-direction: column;
    align-items: center;
  }
}

.reverse-column-order-on-mobile {
  @include globals.in-mobile-layout {
    flex-direction: column-reverse;
    justify-content: space-between;
  }
}

.remove-on-mobile {
  @include globals.in-mobile-layout {
    display: none;
  }
}

.side-element {
  width: 45%;

  > img,
  > svg {
    width: 75%;
  }

  @include globals.in-mobile-layout {
    width: 80%;
    margin-bottom: 2vh;
  }
}

.ignore-margin-left {
  margin-inline-start: var(--negate-current-global-margin);
  justify-content: space-between;

  > .left-element {
    > img,
    > svg {
      width: 100%;
    }
  }

  @include globals.in-mobile-layout {
    margin-inline-start: auto; // this assumes we don't need absolute position in column view...
    justify-content: space-between;
  }
}

.ignore-margin-right {
  margin-inline-end: var(--negate-current-global-margin);
  justify-content: space-between;

  > .right-element {
    > img,
    > svg {
      width: 100%;
    }
  }

  @include globals.in-mobile-layout {
    margin-inline-end: inherit; // this assumes we don't need absolute position in column view...
    justify-content: space-between;
  }
}
