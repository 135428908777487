@use '../../../globals.scss';

.app-section {
  position: relative;
  width: 100%;

  > .wobble {
    position: absolute;
    height: auto;
    width: 45%;
    z-index: -5;
    top: -11%;

    @include globals.in-mobile-layout {
      width: 70%;
      top: -5%;
    }
  }

  > .left-wobble {
    left: 0;
    margin-left: var(--negate-current-global-margin);
  }

  > .right-wobble {
    right: 0;
    margin-right: var(--negate-current-global-margin);
  }
}

.info-section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  > p,
  h1,
  h2 {
    text-align: left;
  }

  > .google-play-button {
    > img {
      width: 200px;
    }
  }
}

.screenshots-section {
  display: grid;
  justify-items: center;
  align-items: flex-start;
  justify-content: space-between;
  grid-template-columns: repeat(4, 22%);

  @include globals.in-mobile-layout {
    grid-template-columns: repeat(2, 44%);
    grid-template-rows: repeat(2, 44%);
    justify-content: space-around;
    align-content: space-between;
  }

  margin-top: 10%;
  margin-bottom: 15%;

  > div {
    height: fit-content;
    width: 100%;

    > img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
}
