@use '../../../globals';

.container-SquareTile {
  min-width: 300px;
  position: relative;
  width: 100%;
  background-color: white;
  box-shadow: globals.$banner-box-shadow;
  border-radius: globals.$banner-radius-small;
  overflow: hidden;
}

.container-SquareTile:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.square-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;

  grid-template-rows: 65% 35%;
  grid-template-columns: 100%;
  align-items: center;
  justify-content: space-between;
}

.bottom-bar-SquareTile {
  background-color: globals.$veracity-blue;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 80% 20%;

  flex-direction: row;
  align-items: center;
  align-content: start;
  justify-content: space-between;

  .paragraph-SquareTile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a {
      color: white;
    }

    padding-left: 10%;
    justify-content: center;
    height: 100%;

    > * > * > .normal-text,
    .normal-title,
    .small-title,
    .small-text {
      color: white;
      margin-block: 3%;
    }

    .separator-TitleSeparatorText path {
      fill: white;
    }
  }
}

#right-arrow-SquareTile {
  color: black;

  font-size: calc(4rem + 10%);

  @include globals.in-tablet-layout {
    font-size: 3rem;
  }

  @include globals.in-mobile-layout {
    font-size: 3rem;
  }
  opacity: 40%;
}

.veracityGreen {
  background-color: globals.$veracity-green !important;
}
