.TitleSeparatorTextComponent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  min-width: 4cm;
}

.separator-TitleSeparatorText {
  width: auto;
  height: 8px;
}

.component-TitleSeparatorText {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  > * {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: auto;
    margin-bottom: auto;
  }
}
