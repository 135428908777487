@use 'src/globals';

#container-SignUpTodayBanner {
  justify-content: center;
  padding-top: 5vw;
}

#bar-SignUpTodayBanner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;

  box-shadow: globals.$banner-box-shadow;
  border-radius: globals.$banner-radius-large;
  background-color: white;

  // override TitleSeparatorTextComponent's title
  > * > * > * > .title-TitleSeparatorText {
    @include globals.in-laptop-layout {
      width: 85%;
    }
  }

  // override horizontal-element's padding
  > * > .side-element {
    padding-bottom: 3%;
    padding-top: 3%;
  }

  @include globals.in-mobile-layout {
    width: 90%;
  }
}

#paragraph-SignUpAsAnAdvertiserToday {
  width: 40%;
  margin-left: 5%;
}

#graphic-SignUpAsAnAdvertiserToday {
  width: 45%;
}

#element-GreenBlueCorner {
  width: 15vw;
  position: absolute;
  top: 0;
  right: 0;
}

#element-lined-circle {
  width: 15vw;
  position: absolute;
  top: -5vw;
  left: -5vw;
  z-index: -1;
}
