@use '../../../../globals';

#android-apps-paragraph-mobile-version {
  margin-inline: var(--current-global-margin);
  @include globals.in-larger-than-phone-layout {
    display: none;
  }
}

#content-container-VeracityAdsAndroidApps {
  display: flex;
  flex-direction: column;
  justify-items: center;
  position: relative;
  width: 100vw;
  height: 0;
  padding-bottom: 70%;
  top: 50%;

  a {
    color: white;
  }

  background-repeat: no-repeat;
  background-image: url('../../assets/background-VeracityAdsAndroidApps.svg');
  @include globals.in-mobile-layout {
    display: flex;
    flex-direction: column;
    justify-items: center;
    background-image: url('../../assets/background-sm-VeracityAdsAndroidApps.svg');
  }
}

#content-VeracityAdsAndroidApps {
  width: 85%;
  margin-top: 13%;

  display: grid;
  grid-template-rows: 30% 70%;
  align-content: start;

  @include globals.in-mobile-layout {
    padding-left: 4vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  > #android-apps-paragraph-desktop-version {
    height: min-content;
    margin-bottom: -50px;

    > .normal-text,
    .normal-title,
    p {
      color: white;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }

    @include globals.in-tablet-layout {
      > .normal-title {
        margin-top: 0.5rem;
      }

      > .normal-text {
        margin-top: 0;
        font-size: 1rem;
      }
    }

    @include globals.in-mobile-layout {
      display: none;
    }
  }
}

#android-apps-list {
  margin-top: 2rem;
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 49%;

  @include globals.in-tablet-layout {
    width: 54%;
    margin-top: 0;
  }

  @include globals.in-mobile-layout {
    display: none;
  }
}

.android-app {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  margin-left: 40px;
  margin-bottom: 5%;

  @include globals.in-tablet-layout {
    margin-bottom: 2%;
  }

  @include globals.in-mobile-layout {
    margin-left: 0;
    align-items: center;
  }

  > .android-app-paragraph {
    margin-left: 0.5rem;

    //width: 100%;
    > h1,
    h2,
    h3,
    h4 {
      margin-top: 0;
      margin-bottom: 0;
    }

    > p {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

.android-app-logo {
  max-width: 18%;
  width: 50%;
  min-height: 100px;
  @include globals.in-mobile-layout {
    max-width: 22%;
  }
}

#android-apps-carousel {
  @include globals.in-larger-than-phone-layout {
    display: none;
  }
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  width: 85vw;

  > .swiper-wrapper > .swiper-slide {
    height: 100%;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  > .swiper-pagination {
    height: 10%;
    position: absolute;
    bottom: 10%;
  }
}
