@use '../../../../globals.scss';

#container-OurKeyAdvantages {
  width: fit-content;
  justify-content: center;
}

#grid-OurKeyAdvantages {
  display: grid;
  justify-items: center;
  align-items: start;
  justify-content: space-around;
  grid-template-columns: repeat(4, 22%);
  grid-template-rows: auto auto;

  .small-text {
    font-size: 1rem;
  }

  > .key-reason-icon {
    width: 75%;
    max-width: 200px;
    min-width: 140px;
  }

  @include globals.in-mobile-layout {
    grid-template-columns: repeat(2, 45%);
    grid-template-rows: repeat(4, auto);
    justify-content: space-around;
    align-content: space-between;

    .small-text {
      font-size: 0.9rem;
    }

    #key-reason-icon3 {
      order: -1
    }
    #key-reason-icon4 {
      order: -1
    }

    #key-reason3 {
      order: -1
    }
    #key-reason4 {
      order: -1
    }
  }

}
