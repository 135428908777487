:root {
  font-size: 100%; // accepting the browsers set font-size (usually 16px) - important for accessibility!
}

// ******************************************************************************
// sass variables - replaced at compile time. should not be used with sass mixins
// ******************************************************************************

// breakpoints
$mobile-layout-width-breakpoint: 768px;
$tablet-layout-width-breakpoint: 1024px;
$laptop-layout-width-breakpoint: 1800px;
// colors
$veracity-green: #0fc571;
$veracity-blue: #33aac6;
$veracity-light-blue: #99d4e2;
// banner design
$banner-radius-large: 20px;
$banner-radius-small: 10px;
$banner-box-shadow: 1mm 1mm 10mm 1mm lightgrey;

// ******************************************************************************
// css variables - can be changed in runtime
// ******************************************************************************
:root {
  --desktop-margin-size: 10vw;
  --mobile-margin-size: 5vw;
  --current-global-margin: var(--desktop-margin-size);
  --negate-current-global-margin: calc(-1 * var(--current-global-margin));
}

// ******************************************************************************
// sass mixins
// ******************************************************************************

// applies inserted css code in viewport width of up to $mobile-layout-width-breakpoint
// usage:
// @use 'src/globals';
// @include globals.in-mobile-layout { <css/sass code> };
@mixin in-mobile-layout {
  @media only screen and (max-width: $mobile-layout-width-breakpoint) {
    @content;
  }
}

@mixin in-tablet-layout {
  @media only screen and (min-width: $mobile-layout-width-breakpoint) and (max-width: $tablet-layout-width-breakpoint) {
    @content;
  }
}

@mixin in-laptop-layout {
  @media only screen and (min-width: $tablet-layout-width-breakpoint) and (max-width: $laptop-layout-width-breakpoint) {
    @content;
  }
}

@mixin in-desktop-layout {
  @media only screen and (min-width: $laptop-layout-width-breakpoint) {
    @content;
  }
}

@mixin in-larger-than-phone-layout {
  @media only screen and (min-width: $mobile-layout-width-breakpoint) {
    @content;
  }
}

// ******************************************************************************
// update global css variables according to viewport width
// ******************************************************************************

@include in-mobile-layout {
  :root {
    --current-global-margin: var(--mobile-margin-size);
  }
}

@include in-larger-than-phone-layout {
  :root {
    --current-global-margin: var(--desktop-margin-size);
  }
}
