@use 'src/globals';

#navigation-menu {
  display: flex;
  justify-content: center;
  background-color: white;
  width: 100vw;
  height: min-content;
  top: 0;
  visibility: visible;

  box-shadow: 0 4mm 2mm -2mm lightgray;

  transition: 0.2s;

  @media only screen and (min-width: 1200px) {
    visibility: hidden;
    transition: 0.2s;
  }
}
