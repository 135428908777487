@use '../../globals.scss';

#careers-section {
  display: flex;
  // flex-wrap: nowrap;

  flex-direction: row;

  justify-content: space-between;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
	}
}
