@use 'src/globals';

#container-BoostGrowthAtEveryStage {
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: space-between;
}

#grid-BoostGrowthAtEveryStage {
  margin-top: 3%;
  display: grid;
  justify-items: center;
  align-items: flex-start;
  justify-content: space-between;
  grid-template-columns: repeat(4, 20%);

  @include globals.in-mobile-layout {
    grid-template-columns: repeat(2, 40%);
    grid-template-rows: repeat(2, 50%);
    justify-content: space-around;
    align-content: space-between;
  }
}
