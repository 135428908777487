@use '../../../../globals';

#container-WhyChooseVeracityAds {
  position: relative;
  display: flex;
  flex-direction: row;
}

#content-WhyChooseVeracityAds {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  flex-wrap: wrap;

  box-shadow: globals.$banner-box-shadow;
  border-radius: globals.$banner-radius-small;
  background-color: white;

  > #learn-more-button-wrapper > * {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
  }
}

#grid-WhyChooseVeracityAds {
  padding-top: 3%;
  height: fit-content;
  display: grid;
  justify-items: center;
  align-items: flex-start;
  justify-content: space-around;
  grid-template-columns: repeat(4, 20%);

  @include globals.in-mobile-layout {
    grid-template-columns: repeat(2, 45%);
    grid-template-rows: repeat(2, 50%);
    justify-content: space-around;
    align-content: space-between;
  }
}

#bg-element-WhyChooseVeracityAds {
  position: absolute;
  z-index: -1;
  left: 0;
  top: -5%;
  width: 26.5%;

  margin-inline-start: var(--negate-current-global-margin);

  @include globals.in-tablet-layout {
    width: 40%;
  }

  @include globals.in-mobile-layout {
    display: none;
  }
}

#lined-circle-element-WhyChooseVeracityAds {
  position: absolute;
  right: -7%;
  bottom: 0;
  z-index: -1;

  // height: 10px;

  //zoom: 0.5;

  @include globals.in-mobile-layout {
    right: 0;
    bottom: -7%;
  }
  //width: 40%;
}
