@use '../../../globals';

#container-ContactUsBanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  position: relative;
  z-index: 10;

  > h1, h2, h3, h4, h5 {margin: 0;}

  > #heading-and-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 60%;
    max-width: 800px;
    padding-inline: 20px;

    background-color: white;

    box-shadow: globals.$banner-box-shadow;
    border-radius: globals.$banner-radius-small globals.$banner-radius-small 0 0;
  }
}

#banner-ContactUs {
  width: 80%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 2vw 2vw 0 2vw;

  position: relative;

  box-shadow: globals.$banner-box-shadow;
  border-radius: globals.$banner-radius-small;
  background-color: white;
  margin-bottom: 10%;

  @include globals.in-mobile-layout {
    flex-direction: column-reverse;
  }
}

#form-wrapper {
  width: fit-content;
}

#contact-form{
  width: 100%;
}

#contact-us-background-element-green {
  border-radius: globals.$banner-radius-small;
  background-color: globals.$veracity-green;
  width: 40%;
  padding-top: min(40%, 40vh);
  box-sizing: border-box;

  position: absolute;
  z-index: -3;
  bottom: -10%;
  right: -5%;
}

#contact-us-background-element-blue {
  border-radius: globals.$banner-radius-small;
  background-color: globals.$veracity-blue;
  width: 100%;
  height: 80%;

  position: absolute;
  z-index: -1;
  bottom: -5%;
  left: -5%;
}

#contact-us-element-lined-circle {
  border-radius: 50%;
  opacity: 50%;
  width: 10vw;
  height: 10vw;
  background: repeating-linear-gradient(
                  45deg,
                  transparent,
                  transparent 0.4vw,
                  globals.$veracity-blue 0.2vw,
                  globals.$veracity-blue 0.6vw
  );
  position: absolute;
  top: -3vw;
  right: -3vw;
  z-index: -5;
}

#contact-us-element-dotted-square {
  width: 10vw;

  position: absolute;
  bottom: -5vw;
  left: -6vw;
  z-index: -1;
}
