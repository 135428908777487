.IconTitleText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.title-IconTitleText {
}

.text-IconTitleText {
}

.icon-IconTitleText {
  width: 50%;
}
