@use 'src/globals';

#contact-page {
  > * > .contact-us-text {
    width: 65%;

    @include globals.in-tablet-layout {
      width: 80%;
    }

    @include globals.in-mobile-layout {
      width: 100%;
    }
  }
}
