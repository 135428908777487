@use 'src/globals';

#container-VeracityAdsChromeExtensions {
  position: relative;

  .chrome-extensions-top-text {
    margin-bottom: 5%;
  }
}

#extensions-VeracityAdsChromeExtensions {
  position: relative;
}

#chrome-extensions-list {
  position: relative;
  @include globals.in-mobile-layout {
    display: none;
  }
}

#background-ChromeExtensions {
  position: absolute;
  width: 80%;
  right: 0;
  top: 0;

  z-index: -5;
  transform: translateY(-5%);
}

#element-dotted-square-ChromeExtensions {
  position: absolute;
  top: 0;
  width: 20%;
  transform: translate(-20%, -20%);
}

#chrome-extensions-carousel {
  width: 85vw;
  padding-bottom: 15%;
  @include globals.in-larger-than-phone-layout {
    display: none;
  }

  > .swiper-wrapper > .swiper-slide {
    height: 100%;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  > .swiper-pagination {
    height: 10%;
    position: absolute;
    bottom: -5%;
  }
}
