@use './src/globals';

#navigation-bar {
  display: flex;
  flex-direction: column;

  position: sticky;
  top: 0;
  width: 100%;
  margin-bottom: 7vw;
  z-index: 1000;

  background-color: transparent;
  transition: 0.2s;

  .MuiButton-contained {
    margin-bottom: 0px;
    margin-left: 0px;
  }

  @media screen and (max-width: 1200px) {
    .MuiButton-contained {
      margin-bottom: 30px;
      margin-left: -15px;
    }
  }

  > #main-content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    height: min-content;
    padding-block: 20px;

    margin-inline: var(--current-global-margin);

    @include globals.in-mobile-layout {
      justify-content: space-between;
      > #veracityads-logo-link {
        > img {
          width: 20vw !important;
          min-width: 100px;
          padding-left: 15% !important;
        }
      }
    }

    > #veracityads-logo-link {
      > img {
        width: 150px;
        display: block;
      }
    }

    @media screen and (max-width: 1200px) {
      > #navigation-links-container {
        display: none;
      }
    }

    > #navigation-links-container {
      > a {
        color: white;
        font-family: Rubik;
      }
    }
  }
}

#navigation-bar.navigation-bar-unscrolled {
  background-color: transparent;
}

#navigation-bar.navigation-bar-scrolled {
  background-color: white !important;

  .MuiButton-contained {
    background-color: globals.$veracity-green;

    > a {
      color: white;
    }
  }

  > #main-content-container {
    > #navigation-links-container {
      > a {
        color: black !important;
      }
    }

    > #navigation-button {
      > label {
        > span {
          background-color: black !important;
        }
      }
    }
  }
}

#navigation-bar.menu-open {
  background-color: white !important;

  .MuiButton-contained {
    background-color: globals.$veracity-green;

    > a {
      color: white;
    }
  }

  > #main-content-container {
    > #navigation-links-container {
      > a {
        color: black !important;
      }
    }

    > #navigation-button {
      > label {
        > span {
          background-color: black !important;
        }
      }
    }
  }
}
