@use '../../../../globals';

.vertical-employee-container {
  width: 33%;
  justify-content: space-between;
  margin-bottom: 60px;

  img {
    width: 60%;
  }

  > #content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > h4 {
      font-size: calc(10px + 0.5vw);
      color: #22a0bf;
      margin-block: 0;
      margin-bottom: 25px;
    }

    > .small-title {
      font-size: calc(15px + 0.5vw);
      color: black;
      margin-top: 25px;
      margin-bottom: 8px;
    }

    > .social-buttons-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.horizontal-employee-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5%;

  img {
    width: 30%;
    margin-right: 50px;
    float: left;
  }

  > #content-container {
    display: inline-block;
    text-align: left;

    > h1 {
      margin-bottom: 10px;
    }

    > h4 {
      font-size: calc(13px + 0.5vw);
      color: #22a0bf;
      margin-block: 0;
      margin-bottom: 15px;
    }

    > .social-buttons-container {
      display: flex;

      align-items: flex-start;
      justify-content: center;
    }
  }
}

#text {
  font-size: calc(7px + 0.5vw);
}

@include globals.in-mobile-layout {
  .vertical-employee-container {
    min-width: 40vw;
    max-width: 0;
    margin-bottom: 50px;

    img {
      width: 75%;
      margin: 0;
    }
  }

  .horizontal-employee-container {
    align-items: flex-start;
    padding-bottom: 50px;
    img {
      width: 45%;
      margin-right: 50px;
    }
  }
}

.social-media-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
}
