@use './src/globals';

#footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-image: url('Assets/footer.svg');
  background-repeat: no-repeat;
  background-size: cover;

  height: 470px;
  width: auto;

  // padding-top 130px;
  padding-top: 10%;
  padding-left: var(--current-global-margin);
  padding-right: var(--current-global-margin);

  a {
    color: black;
  }

  > .main-content {
    display: flex;
    justify-content: space-around;
    flex-direction: row;

    > .section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .move-right {
        margin-left: 25px;
      }

      p {
        margin-block: 5px;
      }

      > .sub-section {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        margin-bottom: 20px;

        > img {
          padding: 5px;
          width: 15px;
        }

        > .text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }

      > .footer-section-seperator {
        background-color: black;

        height: 3px;
        width: 50%;

        border-radius: 50px;
        margin-bottom: 10px;
      }
      > a {
        line-height: 40px;
      }
    }
  }

  > .main-seperator {
    background-color: rgb(128, 128, 128);
    height: 1px;
    width: 100%;
  }

  > .bottom-row {
    display: flex;
    justify-content: space-between;

    #links {
      display: flex;
      align-items: center;

      > .privacy-terms-seperator {
        background-color: black;

        height: 50%;
        width: 2px;

        margin-inline: 10px;
      }
    }
  }
}

@include globals.in-mobile-layout {
  #footer {
    background-image: none;
    background-color: hsl(191, 66%, 86%);
    height: fit-content;
    padding-top: 50px;

    > .main-content {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 50px;

      .section {
        align-items: center;

        > .sub-section {
          align-items: flex-start;
          justify-content: flex-start;
          margin-bottom: 30px;

          > img {
            padding: 5px;
            width: 15px;
          }

          > .text {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }
}
