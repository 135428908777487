@use '../../../../globals';

#navigation-button {
  > label {
    > .navigation-menu-button {
      display: none;
    }

    > input[type='checkbox'] {
      display: none;
    }
  }
}

#navigation-button {
  > label {
    @media screen and (max-width: 1200px) {
      display: flex;
      flex-direction: column;

      width: 45px;
      margin-right: 1rem;
      cursor: pointer;

      span {
        background-color: white;
        border-radius: 10px;
        height: 5px;
        margin: 4px 0;
        transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
      }
      span:nth-of-type(1) {
        width: 100%;
      }

      span:nth-of-type(2) {
        width: 100%;
      }

      span:nth-of-type(3) {
        width: 100%;
      }

      input[type='checkbox']:checked {
        ~ span:nth-of-type(1) {
          transform-origin: bottom;
          transform: rotatez(45deg) translate(9px, 8px);
        }

        ~ span:nth-of-type(2) {
          transform-origin: top;
          transform: rotatez(-45deg);
        }

        ~ span:nth-of-type(3) {
          transform-origin: bottom;
          width: 0;
          transform: translate(23px, -13px) rotatez(45deg);
        }
      }
    }
  }
}
