@use 'src/globals';

// Rubik
@font-face {
  font-family: 'Rubik';
  src: url(assets/fonts/Rubik/Rubik-VariableFont_wght.ttf);
}

@font-face {
  font-family: 'Rubik';
  src: url(assets/fonts/Rubik/Rubik-Italic-VariableFont_wght.ttf);
  font-style: italic;
}

// Avenir
@font-face {
  font-family: 'Avenir';
  src: url('assets/fonts/Avenir/AvenirLTStd45Book.otf');
}

@font-face {
  font-family: 'Avenir';
  src: url('assets/fonts/Avenir/AvenirLTStd65Medium.otf');
  font-weight: auto;
}

@font-face {
  font-family: 'Avenir';
  src: url('assets/fonts/Avenir/AvenirLTStd85Heavy.otf');
  font-weight: bold;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
.normal-title,
.small-title,
.large-title {
  font-family: Rubik;
}

.large-title {
  @include globals.in-larger-than-phone-layout {
    font-size: calc(2rem + 0.5vw);
  }
}

.normal-title {
  @include globals.in-larger-than-phone-layout {
    font-size: calc(1.1rem + 0.5vw);
  }
}

.small-title {
  @include globals.in-larger-than-phone-layout {
    font-size: calc(1rem + 0.25vw);
  }
}

.text,
p,
a {
  font-family: Avenir;
}

.normal-text .small-text {
  font-family: Avenir;
}

.normal-text {
  @include globals.in-larger-than-phone-layout {
    font-size: calc(1rem + 0.5vw);
  }
}

.small-text {
  font-size: calc(0.9rem + 0.25vw);
  @include globals.in-larger-than-phone-layout {
    font-size: 1rem;
  }
}
