@use '../../../globals.scss';

.career-card {
	position: relative;
	background-color: white;

	// enable this style if there is more than 1 card?
	  width: 44%;
	margin-bottom: 50px;

	padding: 20px;
	padding-bottom: 50px;
	text-align: left;

	box-shadow: globals.$banner-box-shadow;
	border-radius: globals.$banner-radius-small globals.$banner-radius-small globals.$banner-radius-small globals.$banner-radius-small;

    @media screen and (max-width: 1200px) {
		width: 90%;
	}

	>p {
		line-height: 16pt;
	}

	>.career-apply-button-container {
		position: absolute;
		display: flex;
		width: 90%;
		justify-content: center;

		top: auto;
		bottom: 0;
	}
}