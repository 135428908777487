@use 'src/globals';

.text-image-button {
  margin-block: 5px;
  height: 5vh;
  width: 12vw;
  min-width: 150px;

  @include globals.in-mobile-layout {
    width: 25vw;
  }
}
